<template>
  <div>
    <a-select
      :value="selectedValue"
      :placeholder="placeholder"
      :size="size"
      style="width: 100%"
      allowClear
      @change="changeValue"
    >
      <a-select-option v-for="item in dataItems" :key="item.id" :value="item.id">
        {{ item.name }}
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
import { userOptionList } from "@/apis/option";

export default {
  props: ["value", "size", "placeholder"],
  model: { prop: "value", event: "change" },
  data() {
    return {
      selectedValue: undefined,
      dataItems: [],
    };
  },
  computed: {
    isManager() {
      return this.$store.state.user.isManager;
    },
    unitName() {
      return this.$store.state.user.unitName;
    },
  },
  methods: {
    changeValue(value) {
      this.selectedValue = value;
      this.$emit("change", value);
    },
  },
  mounted() {
    if (this.value !== undefined) {
      this.selectedValue = this.value;
    }

    if (this.isManager) {
      userOptionList().then((data) => (this.dataItems = data));
    } else {
      userOptionList({ unit_name: this.unitName }).then((data) => (this.dataItems = data));
    }
  },
};
</script>

<style scoped></style>
